<template>
	<div id="saleApply">
		<Navigation />
		<ClassifyChoose></ClassifyChoose>
		<SideNavigation></SideNavigation>
		<Breadcrumb></Breadcrumb>

		<div class="saleapply">
			<div class="saleapply-a flc">
				<div class="saleapply-a-l">
					申请售后
				</div>
				<div class="saleapply-a-r flc">
					<el-image class="saleapply-a-r-l" src="https://t7.baidu.com/it/u=1595072465,3644073269&fm=193&f=GIF"
						fit="cover"></el-image>
					<div class="saleapply-a-r-c">
						<div class="saleapply-a-r-c-t line-two">
							MSA梅思安 安全帽,豪华型有孔,白色ABS带透气孔帽壳,超爱...
						</div>
						<div class="saleapply-a-r-c-b flc">
							<div class="saleapply-a-r-c-b-l">
								¥74.3
							</div>
							<div class="saleapply-a-r-c-b-r">
								（含配送费¥30.00）
							</div>
						</div>
					</div>
				</div>

			</div>

			<div class="saleapply-b">
				<div class="saleapply-b-l">
					服务类型
				</div>
				<div class="saleapply-b-r">
					<div class="saleapply-b-r-i flc">
						<img class="saleapply-b-r-i-l" src="../../assets/img/discounts4.png">
						<div class="saleapply-b-r-i-r">
							退款（无需退货）
						</div>
					</div>
					<div class="saleapply-b-r-i flc">
						<img class="saleapply-b-r-i-l" src="../../assets/img/discounts4.png">
						<div class="saleapply-b-r-i-r">
							退款退货
						</div>
					</div>
				</div>
			</div>

			<div class="saleapply-c flc">
				<div class="saleapply-c-l">
					退款原因
				</div>
				<el-select class="saleapply-c-r" v-model="value" placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>

			<div class="saleapply-d flc">
				<div class="saleapply-d-l">
					退款金额
				</div>
				<el-input class="saleapply-d-r" v-model="input" placeholder="请输入内容"></el-input>
			</div>

			<div class="saleapply-e">
				<div class="saleapply-e-l">
					退款说明
				</div>
				<el-input class="saleapply-e-r" type="textarea" placeholder="请输入内容" v-model="textarea" maxlength="500" show-word-limit>
				</el-input>
			</div>
			
			<div class="saleapply-btn">
				提交申请
			</div>
		</div>

		<Footer></Footer>
	</div>
</template>

<script>
	import Navigation from "../common/navigation.vue"
	import ClassifyChoose from "../common/classify_choose.vue"
	import Breadcrumb from "../common/breadcrumb.vue"
	import SideNavigation from "../common/side_navigation.vue"
	import Footer from "../common/footer.vue"

	export default {
		name: "saleApply",
		components: {
			Navigation,
			ClassifyChoose,
			Breadcrumb,
			SideNavigation,
			Footer
		},
		data() {
			return {
				options: [{
					value: '选项1',
					label: '黄金糕'
				}, {
					value: '选项2',
					label: '双皮奶'
				}, {
					value: '选项3',
					label: '蚵仔煎'
				}, {
					value: '选项4',
					label: '龙须面'
				}, {
					value: '选项5',
					label: '北京烤鸭'
				}],
				value: ''
			}
		}
	}
</script>

<style lang="less">
	@import url("userinfo.less");
</style>
